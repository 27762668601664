import React from "react";
import styles from "./card.module.css";

const render = params => {
  const { backgroundColor, icon, iconFirst, header, body } = params;
  const iconOrder = iconFirst ? "card_icon_order_first" : undefined;
  return (
    // <div className={styles.container + " " + styles[backgroundColor]}>
      <div className={styles.card + " " + styles[backgroundColor]}>
        <div className={styles.content}>
            <h2>{header}</h2>
            <br />
            <p>{body}</p>
        </div>
        <img
          className={styles.card_icon + " " + styles[iconOrder]}
          src={icon}
          alt="Chat Icon"
        />
      </div>
    // </div>
  );
};

export default render;
