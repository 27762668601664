import React from "react";
import styles from "./banner.module.css";
import banner_logo from "./banner_image.png";
import NavBar from "./Navigation/navigation";
const render = () => {
  return (
    <section id="home" className={styles.banner}>
      <NavBar />
      <div className={styles.banner_container}>
        <div className={styles.banner__main}>
          <h1>
            <span>People are talking.</span>
            <span>Join Drop &amp; See!</span>
          </h1>
          <p>
            Drop&apos;n&apos;See is a brilliant new app that allows you to
            discover exciting new places, meet interesting people and make new
            memories.
          </p>
          <a href="#support" className={styles.button + " " + styles.button_green}>
            Contact Us
          </a>
        </div>
        <div className={styles.image__part}>
          <img src={banner_logo} alt="banner logo" />
        </div>
      </div>
    </section>
  );
};

export default render;
