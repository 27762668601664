import React from "react";
import styles from "./loading.module.css";

const render = () => {
  return (
    <div className={styles.lds_ring}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default render;
