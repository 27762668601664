import React from "react";
import styles from "./register.module.css";
import Loading from "../../helpers/Loading/loading";
import Popup from "reactjs-popup";

class Register extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    loading: false,
    success: undefined
  };

  firstNameChange = event => {
    const firstName = event.target.value;
    const newState = { ...this.state, firstName };
    this.setState(newState);
  };

  lastNameChange = event => {
    const lastName = event.target.value;
    const newState = { ...this.state, lastName };
    this.setState(newState);
  };

  emailChange = event => {
    const email = event.target.value;
    const newState = { ...this.state, email };
    this.setState(newState);
  };

  messageChange = event => {
    const message = event.target.value;
    const newState = { ...this.state, message };
    this.setState(newState);
  };

  submitForm = async () => {
    this.setState({
      loading: true
    });
    const formData = this.state;
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      message: formData.message
    };
    try {
      const response = await fetch("https://dev.dropnsee.com/v1/web/signup", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(body)
      });
      await response.json();
      this.setState({
        success: true
      });
    } catch (error) {
      this.setState({
        success: false
      });
    }
    this.setState({
      loading: false
    });
  };

  render = () => {
    let loadingView;
    let hiddenStyle;
    if (this.state.loading) {
      loadingView = <Loading />;
      hiddenStyle = "hidden";
    }

    let popUp;
    if (this.state.success) {
      popUp = (
        <Popup
          open
          modal
          closeOnDocumentClick
          onClose={() => this.setState({ success: undefined })}
        >
          <span> Request was success! </span>
          {/* <p> Form Sent! </p> */}
        </Popup>
      );
    } else if (this.state.success === false) {
      popUp = (
        <Popup
          open
          modal
          closeOnDocumentClick
          onClose={() => this.setState({ success: undefined })}
        >
          <span style={{ color: "red" }}> Error! Please try again later.</span>
        </Popup>
      );
    }

    return (
      <section id="support" className={styles.container}>
        {loadingView}
        {popUp}
        <div
          className={styles.contact_form_container + " " + styles[hiddenStyle]}
        >
          <div className={styles.field + " " + styles.name}>
            <label className={styles.label} htmlFor="firstName">
              First Name
            </label>

            <input
              onChange={this.firstNameChange}
              className={styles.input}
              id="firstName"
              type="text"
              placeholder="First Name"
            />
          </div>

          <div className={styles.field + " " + styles.name}>
            <label className={styles.label}>Last Name</label>

            <input
              onChange={this.lastNameChange}
              className={styles.input}
              id="lastName"
              type="text"
              placeholder="Last Name"
            />
          </div>

          <div className={styles.field + " " + styles.email}>
            <label className={styles.label}>Email</label>

            <input
              onChange={this.emailChange}
              className={styles.input}
              id="email"
              type="email"
              placeholder="email"
            />
            {/* <p className="help is-danger">This email is invalid</p> */}
          </div>

          <div className={styles.field + " " + styles.message}>
            <label className={styles.label}>Message</label>
            <textarea
              onChange={this.messageChange}
              id="message"
              rows="4"
              className={styles.input + " " + styles.big}
              placeholder="Enter message here..."
            />
          </div>

          <div className={styles.field}>
            <button
              onClick={this.submitForm}
              className={styles.button + " " + styles.button_blue}
            >
              Submit
            </button>
          </div>
        </div>

        <div className={styles.contact_info + " " + styles[hiddenStyle]}>
          <h2>Contact Us</h2>

          <p>
            Interested in more information? Any issues you would like to report?
            Want to check out Drop&apos;n&apos;See before it releases? Please contact us
            by providing the required information and we will respond as soon as
            possible.
          </p>
        </div>
      </section>
    );
  };
}

export default Register;
