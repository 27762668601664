import React from 'react';
import styles from './navigation.module.css';
import logo from './images/logo2x.png'

const render = () => {
  return (
    <div className={styles.nav_container}>
      <div className={styles.logo_container}>
      <img className={styles.logo} src={logo} alt="Logo"/>
      </div>
      <div className={styles.menu}>
        <a href="#home">Home</a>
        <a href="#features">Features</a>
        <a href="#support">Support</a>
        <a href="#aboutus">About Us</a>
      </div>
    </div>
  )
}

export default render;