import React from "react";
import styles from "./philosophy.module.css";
import screenShot2x from "./images/screenshot@2x.png";

const render = () => {
  return (
    <section className={styles.philosophy}>
      <div className={styles.philosophy_content}>
        <div className={styles.flex_container}>
          <h2>Our Philosophy</h2>
          {/* <br/> */}
          <p>
            At Drop&apos;n&apos;See we believe that technology should serve
            people to live better lives. We believe that better lives are lived
            by going out there and discovering the world. We created
            Drop&apos;n&apos;See in hopes that people use it to aid them on
            their adventures. To help them capture memories and to meet new and
            exciting people in the places they visit.
          </p>
          {/* <br/>
        <br/> */}
          <a className={styles.button + " " + styles.button_white} href="#signup">Contact Us</a>
        </div>
      </div>
      <img
        className={styles.screen_shot}
        src={screenShot2x}
        alt="screen_shot"
      />
    </section>
  );
};

export default render;
