import React from 'react';
import Banner from './components/Banner/banner';
import Features from './components/Features/features';
import Philosophy from './components/Philosophy/philosophy';
import Register from './components/Register/register';
import AboutUs from './components/AboutUs/about_us';
import Footer from './components/Footer/footer';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      {/* <Nav /> */}
      <Banner />
      <Features />
      <Philosophy />
      <Register />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default App;
