import React from "react";
import Card from "./Card/card";
import styles from './features.module.css'
import chatImg2x from './images/chat@2x.png'
import locationImg2x from './images/location@2x.png'
import peopleImg2x from './images/people@2x.png'
import picturesImg2x from './images/pictures@2x.png'
const render = () => {
    return (
        <section id="features" className={styles.features}>
            <Card backgroundColor="card_color_white" icon={chatImg2x} header={"Discover cool places nearby."} body={"With a click of a button, you’ll be able to see all the exciting places around you and check in to see what they have to offer."}/>
            <Card backgroundColor="card_color_green" icon={locationImg2x} iconFirst={true}  header={"Meet local people."} body={"You’ll be able to interact with others that have checked in to the places you have. Strike up an exciting conversation with them and see what they have to say."}/>
            <Card backgroundColor="card_color_white" icon={peopleImg2x} header={"Share photos and videos."} body={"Once checked in to a place, you’ll be able to take pictures and photos and share them with others. This will become your personal memory stream in that place."}/>
            <Card backgroundColor="card_color_green" icon={picturesImg2x} iconFirst={true} header={"Make memories."} body={"With every new picture taken, with every video shared and every new person met, new memories will be made, waiting for you, for the next time you come to visit."}/>
        </section>
    )
}

export default render;