import React from 'react';
import styles from './footer.module.css';

const render = () => {
  return (
    <footer className={styles.footer}>
      <p>&copy; Drop&apos;n&apos;See All Rights Reserved</p>
    </footer>
  )
}

export default render;