import React from "react";
import styles from "./about_us.module.css";
import rei_image from "./images/rei_image.png";
import kevin_image from "./images/kevin_image.png";

const render = () => {
  return (
    <section id="aboutus" className={styles.container}>
      <h2>About Us</h2>
      <div className={styles.card_container}>
        <img src={rei_image} alt="Rei Profile" />
        <div className={styles.card_info}>
          <h3>Rei Shaholli</h3>
          <h4>Co-Creater &amp; Co-Builder</h4>
          <br />
          <p>
            First generation Albanian-American who loves to write, build and
            tinker with software. I have build Drop &amp; See because of the
            great, awesome and fun challenge it presented. My hope is that
            people use it to help them find new and interesting places and
            people. You can find me on facebook or linkedIn as well as write me
            on my personal email at{" "}
            <a href="mailto:rei.shaholli@gmail.com">rei.shaholli@gmail.com</a>.
            Hope to hear from you guys.
          </p>
          <div className={styles.button_container}>
            <a
              href="https://www.facebook.com/rei.shaholli"
              className={styles.facebook}
            >
              {" "}
            </a>
            <a
              href="https://www.linkedin.com/in/rei-shaholli-0003bb56/"
              className={styles.linkedIn}
            >
              {" "}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.card_container + " " + styles.reverse}>
        <img src={kevin_image} alt="Kevin Profile" />
        <div className={styles.card_info + " " + styles.info_reverse}>
          <h3>Kevin Shaholli</h3>
          <h4>Co-Creater &amp; Co-builder</h4>
          <br />
          <p>
            Professional, techie, sports enthusiast with a knack for writing and
            engineering software systems. I developed Drop &amp; See because I
            love writing software. It is my dream that people use it, and enjoy
            using it, just as much as I did building it. You can contact me on
            my facebook page and linkedIn. I am also reachable on my personal
            email at{" "}
            <a href="mailto:kevinshaholli@gmail.com">kevinshaholli@gmail.com</a>
            .
          </p>
          <div className={styles.button_container + " " + styles.icons_right}>
            <a
              href="https://www.facebook.com/kevin.shaholli"
              className={styles.facebook}
            >
              {" "}
            </a>
            <a
              href="https://www.linkedin.com/in/kevin-shaholli-04740256/"
              className={styles.linkedIn + " " + styles.linkedIn_right}
            >
              {" "}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default render;
